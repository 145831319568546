import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import { StructuredText } from "react-datocms";
import Clients from "../components/Clients";
import Features from "../components/Features";
import Layout from "../components/layout";
import theme from "../styles/theme";

const ProductPage = ({ location, data }) => {
  const featuresData = {
    features: data.page.features,
    featuresItems: data.page.featuresItems,
  };
  return (
    <Layout location={location} seo={data.page.seoMetaTags}>
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 100px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 120px;
        `}
      >
        <section
          css={`
            display: flex;
            h1 {
              margin-top: 130px;
              font-size: 64px;
              ${theme.laptop} {
                font-size: 58px;
                margin-top: 40px;
              }
              ${theme.mobile} {
                font-size: 36px;
                margin-top: 10px;
              }
            }
            h2 {
              margin-top: 50px;
              line-height: 110%;
            }
            p {
              font-size: 20px;
              line-height: 120%;
              ${theme.mobile} {
                font-size: 18px;
              }
            }

            > div {
              display: flex;
              flex-direction: column;
              flex: 1;
              flex-basis: 50%;
            }
            .heroLeft {
              max-width: 600px;
              a {
                color: #e00084;
                opacity: 0.7;
                font-weight: 700;
                transition: 0.2s;
                &:hover {
                  opacity: 1;
                }
              }
            }
            .heroRight {
              margin-left: auto;
              max-width: 800px;
              .img {
                margin-top: 100px;
                z-index: 10;
              }
              ${theme.mobile} {
                flex-basis: 100%;
                .img {
                  margin-top: 0;
                }
              }
            }
            ${theme.tablet} {
              flex-wrap: wrap-reverse;
              .heroRight {
                margin-right: auto;
              }
            }
          `}
        >
          <div className="heroLeft">
            <h1>{data.page.title}</h1>
            <StructuredText data={data.page.lead} />
          </div>
          <div className="heroRight">
            <Img
              className="img"
              fluid={data.page.leadImage.fluid}
              alt={data.page.leadImage.alt}
            />
          </div>
        </section>
        <section
          css={`
            padding-top: 60px;
            padding-bottom: 60px;
            .img {
              width: 100%;
              max-width: 1000px;
              height: auto;
              margin: 0 auto;
              img {
                object-fit: contain !important;
              }
            }
          `}
        >
          <Img
            className="img"
            css={`
              margin-top: 30px;
              @media (max-width: 600px) {
                display: none;
              }
            `}
            fluid={data.page.overviewPicture.fluid}
            alt={data.page.overviewPicture.alt}
          />
          <Img
            className="img"
            css={`
              display: none;
              @media (max-width: 600px) {
                display: block;
              }
            `}
            fluid={data.page.overviewPictureMobile.fluid}
            alt={data.page.overviewPictureMobile.alt}
          />
        </section>
        <section
          css={`
            flex-direction: column;
            margin-top: 160px;
            margin-bottom: 70px;
            ${theme.laptop} {
              margin-top: 70px;
            }
            ${theme.mobile} {
              margin-top: 0;
              margin-bottom: 0;
            }
            padding-left: 0;
            padding-right: 0;
            > div:nth-child(even) {
              flex-direction: row-reverse;
            }
          `}
        >
          {data.page.products.map(item => {
            return (
              <div
                css={`
                  display: flex;
                  ${theme.max1600} {
                    padding-left: 0;
                    padding-right: 0;
                  }
                  flex-wrap: wrap;
                  > div {
                    flex: 1;
                  }
                  .img {
                    width: 100%;
                    ${theme.mobile} {
                      width: 300px;
                      margin-bottom: 20px;
                    }
                    img {
                      width: 80%;
                      height: auto;
                      object-fit: contain;
                      object-position: 50% 50%;
                    }
                  }
                  .imgBox {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  }
                  .content {
                    padding-left: 0;
                    flex: 1;
                    display: flex;
                    padding-top: 70px;
                    padding-bottom: 70px;
                    flex-direction: column;
                    justify-content: center;
                    ${theme.mobile} {
                      flex-basis: 100%;
                      padding-top: 0;
                      > div,
                      h3,
                      p {
                        padding-left: 20px !important;
                        padding-right: 20px !important;
                      }
                    }
                    h3 {
                      margin: 0;
                      font-size: 42px;
                      line-height: 110%;
                      color: ${theme.pink};
                      font-weight: 900;
                      ${theme.laptop} {
                        font-size: 42px;
                      }
                      ${theme.mobile} {
                        font-size: 32px;
                      }
                    }
                    > div,
                    h3,
                    p {
                      padding-left: 100px;
                      padding-right: 100px;
                      line-height: 130%;
                      ${theme.tablet} {
                        padding-left: 50px;
                        padding-right: 50px;
                      }
                    }
                    .button {
                      margin-top: 20px;
                      color: ${theme.pink};
                    }
                  }
                `}
              >
                <div className="imgBox">
                  <Img
                    className="img"
                    fluid={item.img.fluid}
                    alt={item.img.alt}
                  />
                </div>
                <div className="content">
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                  <div>
                    <Link
                      className="button"
                      to={`/services/${item.linkToPage?.slug}`}
                    >
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <section
          css={`
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 0;
            padding-bottom: 100px;
          `}
        >
          <video
            width="100%"
            height="auto"
            autoPlay
            playsInline
            muted
            loop
            poster={data.page.animationPoster?.url}
            css={`
              display: block;
              object-fit: contain;
              overflow: hidden;
              width: 100%;
              max-width: 720px;
            `}
          >
            <source src={data.page.animation?.video.mp4Url} type="video/mp4" />
          </video>
        </section>
        <Features data={featuresData} />
        <Clients dataTitle={data.clientsTitle} dataClients={data.clients} />
      </main>
    </Layout>
  );
};

export default ProductPage;

export const query = graphql`
  query PlatformQuery {
    page: datoCmsPlatform {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      leadImage {
        fluid(maxWidth: 720) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      lead {
        value
      }
      overviewPicture {
        fluid(maxWidth: 1400) {
          ...GatsbyDatoCmsFluid
        }
      }
      overviewPictureMobile {
        fluid(maxWidth: 370) {
          ...GatsbyDatoCmsFluid
        }
      }
      products {
        title
        content
        linkToPage {
          slug
        }
        img {
          fluid(maxWidth: 720) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      animation {
        video {
          streamingUrl
          mp4Url
        }
      }
      animationPoster {
        url
      }
      features {
        value
      }
      featuresItems {
        title
        content
        icon {
          url
          alt
        }
      }
    }
    clients: allDatoCmsClient(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          slug
          text
          thumbnail {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
  }
`;
